import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { window } from "browser-monads"
import i18n from "i18next"
import { detectAlpha2CountryInPath, detectLocale } from "@src/utils/country"
import { ghost } from "@src/utils/ghost"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = props => {
  const {
    dataLayer = [],
    siteLanguage,
    title,
    desc,
    banner,
    pathname,
    nofollow,
    noindex,
    alternates,
    schemaMarkup,
    schema,
    social,
    product_info,
    qa_exception,
  } = props

  const [metaTags, setMetaTags] = React.useState([])
  const siteUrl = null
  const defaultTitle = null
  const defaultDescription = null
  const defaultBanner = null
  const dispatch = useContext(GlobalDispatchContext)

  const isBrowser = typeof window !== "undefined" // FIX ** to avoid react-helmet to double execute/load defer scripts

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  useEffect(() => {
    window.cpm = { language: i18n.language }
  }, [])

  const cpm = JSON.stringify({
    language: (
      detectAlpha2CountryInPath(pathname || window.location.pathname) || ""
    )
      .replace("uk", "en")
      .replace("at", "vn")
      .replace("us", "th"),
  })
  const stringifiedDataLayer = JSON.stringify(dataLayer)
  const country =
    detectAlpha2CountryInPath(seo.url) !== ""
      ? detectAlpha2CountryInPath(seo.url)
      : ""

  /*
  let googleOptimizeId = false;
  switch (country) {
    case "uk":
      googleOptimizeId = process.env.GATSBY_GOOGLE_OPTIMIZE_UK_ID;
      break;
    case "us":
      googleOptimizeId = process.env.GATSBY_GOOGLE_OPTIMIZE_US_ID;
      break;
    case "de":
      googleOptimizeId = process.env.GATSBY_GOOGLE_OPTIMIZE_DE_ID;
      break;
  }
  */

  let klarnaApiKey = ""
  switch (country) {
    case "uk":
      klarnaApiKey = process.env.KLARNA_API_KEY_UK
      break
    case "us":
      klarnaApiKey = process.env.KLARNA_API_KEY_US
      break
    case "de":
      klarnaApiKey = process.env.KLARNA_API_KEY_DE
      break
    case "it":
      klarnaApiKey = process.env.KLARNA_API_KEY_IT
      break
  }

  const fetchDisclamer = () => {
    return new Promise(resolve => {
      let options = {
        type: "compact",
        callback: function callback(res) {
          resolve(res)
        },
      }

      if (detectLocale() === "en-US") {
        options.profile = "us"
      }

      window.gproxy.consents.profile(options)
    })
  }
  const setLang = lang => {
    switch (lang) {
      case "en_US":
        return "en"
      case "de_AT":
        return "de"
      default:
        return lang
    }
  }
  const getAccountInfo = () => {
    window.gproxy.accounts.getAccountInfo({
      callback: function (res) {
        if (res.status == "OK") {
          console.log("ok from getaccountinfo", res)
          window.location.href = `/${
            detectAlpha2CountryInPath(seo.url)
              ? detectAlpha2CountryInPath(seo.url)
              : "uk"
          }/myarea`
        }
      },
    })
  }

  const generatePassword = () => {
    const length = 16
    let result = ""
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  // Gproxy init
  useEffect(() => {
    let name
    // `${process.env.GATSBY_GPROXY_NEWSLETTER_URL}&lang=${
    //   i18n.language === "en_US" ? "en" : i18n.language
    // }`
    ghost(
      "gproxy",
      `${process.env.GATSBY_GPROXY_URL}&lang=${
        // i18n.language === "en_US" ? "en" : i18n.language
        setLang(i18n.language)
      }`
    ).then(res => {
      console.debug(
        "GProxy is avaiable from now on using window.gproxy...",
        window.gproxy.session.native()
      )

      if (res.event && res.event.success) {
        console.log(
          "RESULT success GPROXY+++",
          res.event,
          res.event.registration
        )
        getAccountInfo()
      }
      if (res.event && res.event.registration) {
        const data = {
          acquisitionOwner: detectAlpha2CountryInPath(seo.url),
          acquisitionSource: "E-COMMERCE",
          identityType: 2,
          lastSysModBy: "COM",
        }
        var prefs = window.gproxy.consents.serialize({
          profile: true,
          input: {
            GDPRPromo: false,
            GDPRPromo3rd: false,
            GDPRProfiling: false,
          },
        })

        window.gproxy.accounts.register({
          //gigya.accounts.register({
          email: res.event.data.email,
          regToken: res.event.data.regToken,
          data: data,
          sessionExpiration: -2,
          profile: {
            firstName: res.event.data.firstName,
            lastName: res.event.data.lastName,
            locale: props.siteLanguage,
          },
          //locale: locale,
          lang: props.siteLanguage,
          password: generatePassword(),
          finalizeRegistration: true,
          preferences: prefs,
          callback: res => {
            console.log("REGISTRATION2 SUCCESS", res)
            window.location.href = `/${
              props.country ? props.country : "uk"
            }/myarea`
          },
        })
      }

      if (res.event && res.event.conflict) {
        // Re-login to site account needed to merge it with social account
        console.log("Re-login needed...", res.event)
        let password = prompt(
          "Insert password for account [" + res.event.account.loginID + "]"
        )
        window.gproxy.oauth2.link({
          event: res.event,
          password: password,
          callback: function (response) {
            console.log("Account link result", response)
            getAccountInfo()
          },
        })
        return
      }

      window.gproxy.accounts.getAccountInfo({
        callback: function (res) {
          if (res.status == "OK") {
            name = res.profile.firstName
            dispatch({
              type: "getAccountInfo",
              payload: { userName: name },
            })
          }
        },
      })

      fetchDisclamer().then(res => {
        dispatch({
          type: "getNewsletterInfo",
          payload: { text: res?.disclaimer?.text },
        })
      })
    })
  }, [])

  const parseValue = value => {
    if (typeof value === "string") {
      return value
    }
    if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null &&
      "src" in value
    ) {
      return value.src
    }
  }

  const putMetaObj = (array, key, value) => {
    const descriminator = key.startsWith("og:") ? "property" : "name"
    const index = array.findIndex(el => el[descriminator] === key)
    if (value && !Array.isArray(value)) {
      if (index > -1) {
        array.splice(index, 1)
      }
      array.push({
        [descriminator]: key,
        content: parseValue(value),
      })
    }
    return array
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={detectLocale()} />
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${window.location.origin}${pathname}`} />
        {(alternates || []).map((a, k) => (
          <link key={k} rel="alternate" href={a.href} hreflang={a.hreflang} />
        ))}
        {typeof noindex !== "undefined" &&
        noindex !== null &&
        typeof nofollow !== "undefined" &&
        nofollow !== null ? (
          <meta
            name="robots"
            content={[
              (noindex && "noindex") || "index",
              (nofollow && "nofollow") || "follow",
            ]
              .filter(e => !!e)
              .join(",")}
          />
        ) : null}
        {/* Social */}
        {social?.facebook_title ? (
          <meta property="og:title" content={social?.facebook_title}></meta>
        ) : null}
        {social?.facebook_description ? (
          <meta
            property="og:description"
            content={social?.facebook_description}
          ></meta>
        ) : null}
        {social?.facebook_image ? (
          <meta
            property="og:image"
            content={social?.facebook_image?.src}
          ></meta>
        ) : null}
        <meta property="og:type" content="website" />
        {social?.twitter_title ? (
          <meta name="twitter:title" content={social?.twitter_title}></meta>
        ) : null}
        {social?.twitter_description ? (
          <meta
            name="twitter:description"
            content={social?.twitter_description}
          ></meta>
        ) : null}
        {social?.twitter_image ? (
          <meta
            property="twitter:image"
            content={social?.twitter_image?.src}
          ></meta>
        ) : null}
        <meta name="twitter:card" content="summary" />
        {/* QA meta tags */}
        {qa_exception ? (
          <meta name="qa_exception_cta" content={qa_exception}></meta>
        ) : (
          <meta name="qa_exception_cta" content={qa_exception}></meta>
        )}
        {product_info?.pre_order ? (
          <meta name="qa_pre_order" content={product_info?.pre_order}></meta>
        ) : (
          <meta name="qa_pre_order" content={product_info?.pre_order}></meta>
        )}
        {product_info?.notify_me ? (
          <meta name="qa_notify_me" content={product_info?.notify_me}></meta>
        ) : (
          <meta name="qa_notify_me" content={product_info?.notify_me}></meta>
        )}
        {product_info?.product_id && product_info?.product_id !== null ? (
          <meta name="qa_product_id" content={product_info?.product_id}></meta>
        ) : null}
        {product_info?.product_universal_title &&
        product_info?.product_universal_title !== null ? (
          <meta
            name="qa_product_universal_title"
            content={product_info?.product_universal_title}
          ></meta>
        ) : null}
        {/* Schema markup */}
        {schemaMarkup ? (
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        ) : null}
        {schema ? <script type="application/ld+json">{schema}</script> : null}
        {/* {isBrowser && country !== "us" ? (
          <script
            src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script={`${process.env.GATSBY_COOKIEPRO_URL}`}
          ></script>
        ) : (
          <script
            src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script={`${process.env.GATSBY_COOKIEPRO_URL_US}`}
          ></script>
        )}
        {isBrowser && (
          <script type="text/javascript">function OptanonWrapper() {}</script>
        )} */}
        {isBrowser && country !== "" && country !== "de" && (
          <script type="text/javascript">{`
            window.dataLayer = window.dataLayer || [];
            var cpm = ${cpm};

            function gtag() { window.dataLayer.push(arguments); }

            (function(h,u,b){
            var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
            e.async=true;e.src='${process.env.GATSBY_COOKIEHUB_URL}';
            e.onload=function(){u.cookiehub.load(b);}
            d.parentNode.insertBefore(e,d);
            })(document,window,cpm);
        `}</script>
        )}{" "}
        {isBrowser && country !== "" && country === "de" && (
          <script type="text/javascript">{`
        window.dataLayer = window.dataLayer || [];
        var cpm = ${cpm};

        function gtag() { window.dataLayer.push(arguments); }

        (function(h,u,b){
        var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
        e.async=true;e.src='${process.env.GATSBY_COOKIEHUB_URL_DE}';
        e.onload=function(){u.cookiehub.load(b);}
        d.parentNode.insertBefore(e,d);
        })(document,window,cpm);
    `}</script>
        )}
        {isBrowser && (
          <script
            defer="defer"
            type="text/javascript"
            src={`${process.env.GATSBY_GBUGS_URL}`}
          ></script>
        )}
        {isBrowser && (
          <script type="text/javascript">{`
            window.dataLayer = window.dataLayer || [];
            window.dataLayerEvents = window.initialDataLayer = ${stringifiedDataLayer};

            function dataLayerPushLoop() {
              window.dataLayerEvents.forEach(event => {
                window.dataLayer.push(event);
              });
            }

            if (window.dataLayerEvents) {
              dataLayerPushLoop();

              /*
              if ('${process.env.NODE_ENV}' == 'development') {
                dataLayerPushLoop();
              } else {
                window.addEventListener("load", function(e) {
                  dataLayerPushLoop();
                });
              }
              */

              // extract cdevent
              window.initialDataLayer.cdevent = window.dataLayerEvents.find(function (e) { return e.event === 'cdevent'; });

              // extract product
              const evtProductDetail = window.dataLayerEvents.find(function (e) { return e.event === 'productDetail'; });

              if (evtProductDetail && evtProductDetail.ecommerce && evtProductDetail.ecommerce.detail) {
                const products = evtProductDetail.ecommerce.detail.products;

                if (products && products.length > 0) {
                  window.initialDataLayer.product = products[0];
                }
              }
            }

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');
          `}</script>
        )}
        {isBrowser && (
          <script
            type="text/javascript"
            src={`${process.env.GATSBY_ABTASTY_URL}`}
          ></script>
        )}
        {/* Scatola di fiducia pilotas */}
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
        <script
          type="text/javascript"
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=32ee2df5-ba92-481e-9d56-592ead1f208b"
        ></script>
        <script type="text/javascript">
          {`
          zE(function ()
          {$zopim(function () {
            const allowedDepartments = {
              de: {name: 'Deutschland', lang: "de", id: 5573572593425},
              nl: {name: 'Holland', lang: "nl", id: 360009966577},
              at: {name: 'Österreich', lang: "en", id: 5573564735633},
              uk: {name: 'United Kingdom', lang: "en", id: 360009955978},
              us: {name: 'United States', lang: "en", id: 360007087478},
              gr: {name: 'Ελλάς', lang: "el", id: 360008273738}
            }

            if (allowedDepartments.hasOwnProperty("${country}")) {
              $zopim.livechat.setLanguage(allowedDepartments["${country}"].lang);
            } else {
              document.cookie = "__zlcmid" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              $zopim.livechat.departments.clearVisitorDepartment();
              $zopim.livechat.setLanguage("en");
              $zopim.livechat.hideAll();
            }

            $zopim.livechat.window.onShow(function() {
              setTimeout(() => {
                if(allowedDepartments.hasOwnProperty("${country}")) {
                  $zopim.livechat.departments.setVisitorDepartment(allowedDepartments["${country}"].id);
                  var iframe = document.getElementById('webWidget');
                  var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                  var el = innerDoc.querySelector('[data-garden-id="dropdowns.faux_input"]');
                  var el2 = innerDoc.querySelector('[data-fieldid="department"]');
                  el.style.pointerEvents = "none";
                  el2.style.pointerEvents = "none";
                }
              },1000)
            })
            
          })}
          );
          `}
        </script>
        {/* Klarna */}
        <script
          async
          type="text/javascript"
          data-environment="playground"
          src="https://js.klarna.com/web-sdk/v1/klarna.js"
          data-client-id={`${klarnaApiKey}`}
        ></script>
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  social: PropTypes.object,
  product_info: PropTypes.object,
  qa_exception: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  social: null,
  product_info: null,
  qa_exception: null,
}
